<template>
  <div class="section-form">
    <div class="mb-3">
      <div class="d-flex justify-content-between name-select-container">
        <span class="mb-1">Name :</span>
        <a-select v-model="selectedLangId" size="small" :showArrow="false" class="lang-select">
          <a-select-option v-for="lang in langs" :key="lang.value">
            {{ lang.label }}
          </a-select-option>
        </a-select>
        <span class="title-class">Show label</span>
      </div>
      <div class="d-flex">
        <a-input class="mr-4" v-model="currentSectionName" :placeholder="placeholderName" size="large"
          :disabled="loading" />
        <div class="d-flex flex-column justify-content-center label-container">
          <a-switch @change="handleSwitchChange" :checked="showLabel" :disabled="loading" />
        </div>
      </div>
    </div>
    <div class="d-flex flex-column mb-5">
      <span class="mb-1">Type :</span>
      <a-select v-model="sectionType" size="large" :disabled="isEditMode">
        <a-select-option v-for="type in sectionTypes" :key="type.value">
          {{ type.label }}
        </a-select-option>
      </a-select>
    </div>
    <hr>
    <a-row>
      <a-col :span="24">
        <div class="d-flex justify-content-between">
          <a-popconfirm v-if="isEditMode" title="Do you really want to delete it?" @confirm="deleteSection">
            <a-button :loading="loading" class="deleteButton"  type="danger" :disabled="loading">
              Delete
            </a-button>
          </a-popconfirm>
          <div class="ml-auto text-right">
            <a-button @click="closeModal" :disabled="loading">
              Cancel
            </a-button>
            <a-button :loading="loading" type="primary" style="margin-left: 10px;" @click="save" :disabled="loading">
              Save
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>

import ViewscreenService from '@/services/api/apiViewscreenService'

export default {
  name: 'sectionModal',
  components: {
  },
  props: ['sectionData', 'langs', 'viewscreenId'],
  data() {
    return {
      selectedLangId: '0',
      sectionType: 'shortcut_small',
      sectionNames: {
        ru: '',
        kk: '',
        en: '',
        cn: '',
        uz: '',
      },
      loading: false,
      showLabel: false,
      sectionTypes: [
        { label: 'Promo images', value: 'promo' },
        { label: 'Informer', value: 'informer' },
        { label: 'Top matches', value: 'topmatches' },
        { label: 'Shortcut small', value: 'shortcut_small' },
        { label: 'Shortcut medium', value: 'shortcut_medium' },
        { label: 'Shortcut large', value: 'shortcut_large' },
        { label: 'Favourite matches', value: 'favourite_matches' },
      ],
      sectionId: '',
    }
  },
  computed: {
    selectedLang() {
      return this.langs.find(lang => lang.value === this.selectedLangId)
    },
    placeholderName() {
      return this.selectedLang ? `${this.selectedLang.label} name` : 'English name'
    },
    currentSectionName: {
      get() {
        return this.sectionNames[this.selectedLang.shortcut]
      },
      set(newValue) {
        this.$set(this.sectionNames, this.selectedLang.shortcut, newValue)
      },
    },
    isEditMode() {
      if (!this.sectionData) {
        return false
      }
      return true
    },
  },
  methods: {
    handleLangChange(lang) {
      this.selectedLangId = lang
    },
    handleSwitchChange() {
      this.showLabel = !this.showLabel
    },
    closeModal() {
      this.$emit('closeSectionModal')
    },
    getSectionPayload() {
      const sectionPayload = {
        type: this.sectionType,
        show_label: this.showLabel,
      }
      const filteredLocalizations = Object.entries(this.sectionNames)
        .filter(([lang, name]) => name !== '')
        .map(([lang, name]) => {
          const langInfo = this.langs.find(l => l.shortcut === lang)
          return {
            lang_id: langInfo ? langInfo.value : null,
            name: name,
          }
        })
        .filter(item => item.lang_id !== null)
      if (filteredLocalizations.length) {
        sectionPayload.localizations = filteredLocalizations
      }
      return sectionPayload
    },
    async createViewscreenSection() {
      try {
        this.loading = true
        const sectionPayload = this.getSectionPayload()
        const sectionResponse = await ViewscreenService.createViewscreenSection(this.viewscreenId, sectionPayload)
        if (sectionResponse.data) {
          this.$notification.success({
            message: 'Section created successfully',
          })
          this.$emit('createSection', sectionResponse.data.data)
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    async editViewscreenSection() {
      try {
        this.loading = true
        const sectionPayload = this.getSectionPayload()
        const sectionResponse = await ViewscreenService.updateViewscreenSection(this.viewscreenId, this.sectionData.id, sectionPayload)
        if (sectionResponse.data) {
          this.$notification.success({
            message: 'Section updated successfully',
          })
          this.$emit('editSection', sectionResponse.data.data)
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    async deleteViewscreenSection() {
      try {
        this.loading = true
        const sectionResponse = await ViewscreenService.deleteViewscreenSection(this.viewscreenId, this.sectionData.id)
        if (sectionResponse.data) {
          this.$notification.success({
            message: 'Section deleted successfully',
          })
          this.$emit('deleteSectionModal', this.sectionData.id)
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    fillSection() {
      const { id, localizations, show_label, type } = this.sectionData
      this.sectionId = id
      this.showLabel = show_label
      this.sectionType = type

      localizations.forEach(lang => {
        const langInfo = this.langs.find(l => {
          return Number(l.value) === Number(lang.lang_id)
        })
        if (langInfo) {
          this.sectionNames[langInfo.shortcut] = lang.value
        }
      })
    },
    save() {
      this.isEditMode ? this.editViewscreenSection() : this.createViewscreenSection()
    },
    deleteSection() {
      this.deleteViewscreenSection()
    },
  },
  mounted() {
    if (this.sectionData) {
      this.fillSection()
    }
  },
}
</script>
<style scoped lang="scss">
.section-form {
  .lang-select.ant-select.ant-select-enabled.ant-select-no-arrow.ant-select-sm {
    color: #1890FF !important;
    min-width: 80px;
  }
  .title-class {
    position: absolute;
    top: 10px;
    right: -56px;
    color:#595959
  }

  .label-container {
    min-width: 70px;
    align-items: flex-start;
  }

  .name-select-container {
    position: relative;
    max-width: 330px;
  }
}
</style>
